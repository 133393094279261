export default {
  'trade-in_title_smartphones': {
    id: 'menu_trade-in_title_smartphones',
    defaultMessage: 'Trade-in',
  },
  'trade-in_description_smartphones': {
    id: 'menu_trade-in_description_smartphones',
    defaultMessage: 'Get up to $900 for your old device',
  },
  'mobile-plan_title_smartphones': {
    id: 'menu_mobile-plan_title_smartphones',
    defaultMessage: 'Exclusive deals',
  },
  'mobile-plan_description_smartphones': {
    id: 'menu_mobile-plan_description_smartphones',
    defaultMessage: 'Select a Visible+ plan at checkout and save',
  },
  'trade-in_title_laptops': {
    id: 'menu_trade-in_title_laptops',
    defaultMessage: 'Trade-in',
  },
  'trade-in_description_laptops': {
    id: 'menu_trade-in_description_laptops',
    defaultMessage: 'Get up to $900 for your old device',
  },
  good_to_know: {
    id: 'good_to_know',
    defaultMessage: 'Good to know',
  },
}
