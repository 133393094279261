<template>
  <div class="flex h-full flex-col">
    <section v-if="extraMenu" class="mb-16">
      <RevList
        class="divide-y-0"
        data-qa="store-nav-list-items"
        :has-external-borders="false"
      >
        <RevListItemInteractive
          data-test="store-nav-list-item-mobile"
          :to="`/${locale}/buyback/home`"
          @click="
            emit('menuItemClick', {
              item: {
                children: [],
                title: i18n(translations.linkTradeIn),
                id: -1,
                url: `/${locale}/buyback/home`,
              },
            })
          "
        >
          <template #prefix>
            <IconSwap class="text-static-brand-mid" />
          </template>

          <template #label>
            <div class="flex items-center">
              <span class="text-static-brand-mid body-1-bold grow">
                {{ i18n(translations.linkTradeIn) }}
              </span>
            </div>
          </template>
        </RevListItemInteractive>
      </RevList>
    </section>

    <section>
      <h2 v-if="extraMenu" class="body-2 mx-24">
        {{ i18n(translations.shop) }}
      </h2>

      <RevList
        class="divide-y-0"
        data-qa="store-nav-list-items"
        :has-external-borders="false"
      >
        <TransitionGroup
          appear
          enter-active-class="transition-all duration-150 ease-out delay-300"
          enter-from-class="opacity-0 -translate-y-2"
          @before-enter="addDelay"
        >
          <RevListItemInteractive
            :key="-1"
            data-test="store-nav-list-item-mobile"
            :to="`/${locale}/e/good-deals`"
            @click="
              emit('menuItemClick', {
                item: {
                  children: [],
                  title: i18n(translations.goodDeals),
                  id: -1,
                  url: `/${locale}/e/good-deals`,
                },
              })
            "
          >
            <template #prefix>
              <IconSparkles class="text-static-brand-low" />
            </template>

            <template #label>
              <div class="flex items-center">
                <span class="text-static-brand-low body-1-bold grow">
                  {{ i18n(translations.goodDeals) }}
                </span>
              </div>
            </template>
          </RevListItemInteractive>

          <RevListItemInteractive
            v-for="(item, index) in menuItems"
            :key="item.title"
            :data-index="index"
            data-test="store-nav-list-item-mobile"
            :to="hasChildren(item) ? undefined : item.url"
            @click="emit('menuItemClick', { item })"
          >
            <template #prefix v-if="item.image">
              <RevIllustration
                alt=""
                class="h-24 w-24 transition-all"
                :height="24"
                :src="item.image"
                :width="24"
              />
            </template>
            <template #label>
              <div class="flex items-center">
                <span class="body-1-bold grow" :style="{ color: item.color }">
                  {{ item.title }}
                </span>

                <IconChevronRight v-if="hasChildren(item)" />
              </div>
            </template>
          </RevListItemInteractive>
        </TransitionGroup>
      </RevList>
    </section>

    <Transition
      appear
      enter-active-class="transition-opacity duration-300 ease-out"
      enter-from-class="opacity-0"
    >
      <div
        class="flex grow flex-col justify-end pb-8"
        :style="`transition-delay: ${maxDelay}ms;`"
      >
        <RevLink
          v-for="link in links"
          :key="link.name"
          class="body-2-link flex items-center py-8 pl-20"
          :to="link.href"
          :underlined="false"
          @click="emit('linkClick', { name: link.name })"
        >
          {{ link.label }}
        </RevLink>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { Locale } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { IconSwap } from '@ds/icons/IconSwap'

import type { Item } from '../composables/useNavigation'

import translations from './BurgerMenu.translations'

const i18n = useI18n()
const locale = useI18nLocale()

const { features }: { features: { buyback: { enabled: boolean } } } =
  useMarketplace()
const extraMenu = computed(() => features.buyback.enabled)

const props = defineProps<{
  menuItems: Item[]
  links: {
    name: string
    href: { name: string; params: { locale: Locale } }
    label: string
  }[]
}>()

const emit = defineEmits<{
  menuItemClick: [{ item: Item }]
  linkClick: [{ name: string }]
}>()

function hasChildren(item: Item) {
  return item.children?.length > 0
}

const maxDelay = props.menuItems.length * 50 + 300 + 150

function getIndex(el: Element) {
  // And the props are injected to first child of <li> of type
  const node = el.querySelector('*') as HTMLAnchorElement | HTMLButtonElement
  if (node.dataset.index) {
    return parseInt(node.dataset.index, 10)
  }

  return undefined
}

function addDelay(el: Element) {
  const li = el as HTMLLIElement

  const index = getIndex(el)
  if (index) {
    li.style.transitionDelay = `${index * 50 + 300}ms`
  }
}
</script>
