<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div>
    <div
      class="bg-overlay-low fixed inset-x-0 bottom-0 left-0 top-[115px] opacity-70"
      @click.self="toggle"
    />
    <section class="fixed bottom-0 left-0 top-[115px] p-0">
      <nav
        class="bg-surface-default-low fixed bottom-[7rem] top-[115px] flex w-256 flex-col overflow-scroll"
      >
        <template v-for="item in menu" :key="item.title">
          <RevLink
            :aria-expanded="item === selected"
            aria-haspopup="true"
            class="focus-visible-outline-inset-hi flex items-center py-16 pl-32"
            data-test="store-nav-list-item"
            :style="item.color"
            :to="item.url"
            :underlined="false"
            @focusin="select(item)"
            @mouseover="select(item)"
          >
            {{ item.title }}
          </RevLink>
          <hr class="border-static-default-low" />
          <MenuPanel
            v-if="item"
            v-show="item === selected"
            class="bg-surface-default-low fixed bottom-0 left-256 top-[115px] w-[50rem] pt-0"
            :item="item"
          />
        </template>
      </nav>
      <MenuFooter class="absolute bottom-0 w-256" />
    </section>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { type MenuItem } from '@backmarket/http-api/src/api-specs-navigation-experience/navigation/sidebar'
import { RevLink } from '@ds/components/Link'

import { useNavigation } from '../composables/useNavigation'

import MenuFooter from './MenuFooter.vue'
import MenuPanel from './MenuPanel.vue'

const { menu, toggle } = useNavigation()

const selected = ref<MenuItem | undefined>(undefined)

function select(item: MenuItem) {
  selected.value = item
}
</script>
