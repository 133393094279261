<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <nav class="bg-surface-default-low">
    <ul
      class="relative flex h-48 items-center justify-center px-32"
      role="menubar"
    >
      <li
        v-for="item in items"
        :key="item.id"
        class="flex h-full flex-nowrap gap-4 border-b-2 px-16 transition-all duration-700 ease-in-out hover:border-static-default-hi"
        :class="[
          isActivePage(item.url) && !isMenuActive
            ? 'border-static-default-hi'
            : 'border-transparent',
        ]"
        role="none"
        @blur="hideMenu"
        @focus="() => showMenu(item.id)"
        @mouseout="hideMenu"
        @mouseover="() => showMenu(item.id)"
      >
        <RevLink
          class="bold-hack border-static-default-low text-action-default-low box-content inline-block rounded-none transition-all duration-700 ease-in-out hover:body-2-bold hover:no-underline"
          :class="[
            isActivePage(item.url) && !isMenuActive ? 'body-2-bold' : 'body-2',
          ]"
          role="menuitem"
          :style="{ color: item.color }"
          :title="item.title"
          :to="item.url"
          :underlined="false"
        >
          <span class="flex h-full flex-nowrap items-center gap-4">
            <component :is="item.icon" v-if="item.icon" />
            <span>{{ item.title }}</span>
          </span>
        </RevLink>
        <div
          v-show="activeMenu === item.id && item.children.length > 0"
          class="absolute inset-x-0 mt-48"
          :class="[isMenuActive ? 'border-static-default-low border-t' : '']"
        >
          <div class="flex">
            <NavMenuSidebar class="max-w-256" :universe="item.title" />
            <NavMenuContent class="flex-1" :items="item.children" />
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevLink } from '@ds/components/Link'
import { IconSparkles } from '@ds/icons/IconSparkles'

import { useNavigation } from '../../composables/useNavigation'
import translations from '../BurgerMenu.translations'

import NavMenuContent from './NavMenuContent.vue'
import NavMenuSidebar from './NavMenuSidebar.vue'

const i18n = useI18n()
const locale = useI18nLocale()
const GOOD_DEALS = {
  title: i18n(translations.goodDeals),
  children: [],
  id: -1,
  color: '#9D3963',
  icon: IconSparkles,
  url: `/${locale}/e/good-deals`,
}

const {
  items: navigationItems,
  setActiveMenu,
  clearActiveMenu,
  activeMenu,
  isMenuActive,
  isActivePage,
} = useNavigation()
const items = computed(() => {
  return [GOOD_DEALS, ...navigationItems.value]
})

const timeout = ref()
function showMenu(id: number) {
  clearTimeout(timeout.value)
  timeout.value = setTimeout(() => {
    setActiveMenu(id)
  }, 150)
}

function hideMenu() {
  clearTimeout(timeout.value)
  timeout.value = setTimeout(() => {
    clearActiveMenu()
  }, 150)
}

onBeforeUnmount(() => {
  clearTimeout(timeout.value)
})
</script>

<style>
.bold-hack::after {
  display: block;
  content: attr(title);
  font-weight: 600;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  contain: content;
}
</style>
