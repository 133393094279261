<template>
  <div
    v-if="features.buyback.enabled || features.mobilePlan.enabled"
    class="bg-surface-default-mid flex flex-col gap-16 p-40 pt-24"
  >
    <h2 class="text-static-default-mid body-2">
      {{ i18n(t.good_to_know) }}
    </h2>
    <ul class="flex flex-col gap-12">
      <li v-if="features.buyback.enabled && tradeInTitle">
        <NavMenuSidebarItem
          :description="tradeInDescription"
          :icon="IconSwap"
          :title="tradeInTitle"
        />
      </li>
      <li v-if="features.mobilePlan.enabled && mobilePlanTitle">
        <NavMenuSidebarItem
          :description="mobilePlanDescription"
          :icon="IconSparkles"
          :title="mobilePlanTitle"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { IconSparkles } from '@ds/icons/IconSparkles'
import { IconSwap } from '@ds/icons/IconSwap'

import type { Features } from '~/features'

import t from './NavMenuSidebar.translations'
import NavMenuSidebarItem from './NavMenuSidebarItem.vue'

const props = defineProps<{ universe: string }>()
const i18n = useI18n()

const { features } = useMarketplace<Features>()

const translations: Record<
  string,
  { id: string; defaultMessage: string } | undefined
> = t

const getTranslation = (key: string) => {
  return computed(() => {
    const translation = translations[`${key}_${props.universe.toLowerCase()}`]
    if (!translation) return undefined

    return i18n(translation)
  })
}

const tradeInTitle = getTranslation('trade-in_title')
const tradeInDescription = getTranslation('trade-in_description')
const mobilePlanTitle = getTranslation('mobile-plan_title')
const mobilePlanDescription = getTranslation('mobile-plan_description')
</script>
